import Link from 'next/link';
import { ReactNode, useEffect, useState } from 'react';
import useMap from '@/lib/useMap';
import { COMPANIES, EQUIPMENT_ITEMS, USERS } from '@/store/selectors';
import { useSilent, useValue } from 'use-change';
import { useRouter } from 'next/router';
import { LoadingPlaceholder } from '@/martsUi/Loading/LoadingPlaceholder/LoadingPlaceholder';
import { Image } from '@/martsUi/Components/Image/Image';
import ProductThumbnail from '@/shared/ProductThumbnail/ProductThumbnail';

export const HomePageYourEquipment = () => {
  const { asPath, pathname } = useRouter();
  const prev = encodeURIComponent(asPath);
  const me = useValue(USERS, 'me');
  const myCompany = useValue(COMPANIES, 'myCompany');
  const loadPersonalEquipment = useSilent(
    EQUIPMENT_ITEMS,
    'loadPersonalEquipment',
  );
  const [loadingEquipment, setLoadingEquipment] = useState(true);
  const equipmentItems = useMap(
    EQUIPMENT_ITEMS.DATA,
    useValue(EQUIPMENT_ITEMS, 'personalEquipmentIds'),
  );

  useEffect(() => {
    // loadPersonalEquipment requires me to be set
    if (!me || !myCompany) return;
    void loadPersonalEquipment().then(() => setLoadingEquipment(false));
  }, [loadPersonalEquipment, me, myCompany]);

  if (loadingEquipment) {
    return (
      <PageLayoutAndTitle>
        <div className={'flex flex-col mt-6 gap-6'}>
          <LoadingPlaceholder height={88} count={3} />
        </div>
      </PageLayoutAndTitle>
    );
  }

  if (equipmentItems.length === 0) {
    return (
      <PageLayoutAndTitle>
        <div className="text-center mt-6">
          <Image
            imageClassName="inline-block"
            src="/images/no-equipment-illustration.png"
            width={83}
            height={100}
            alt="No equipment"
          />
          <h3 className="mt-2 font-medium">No equipment yet</h3>
          <p className="mt-2 text-text-light">
            As you get equipment you will be able to keep track of it here.
          </p>
        </div>
      </PageLayoutAndTitle>
    );
  }

  return (
    <PageLayoutAndTitle>
      <div className="mt-2">
        {equipmentItems.map(({ id, brand, equipmentItemName }) => (
          <Link
            key={id}
            href={`${
              pathname.includes('user-portal') ? '/user-portal' : ''
            }/inventory/${id}?prev=${prev}`}
            className="bg-bg p-4 flex flex-row rounded-2xl mt-4 gap-6"
          >
            <ProductThumbnail
              id={id}
              width={64}
              height={64}
              className="rounded-lg"
            ></ProductThumbnail>
            <div className="truncate">
              <div className="text-text-light">{brand}</div>
              <div className="mt-2 truncate">{equipmentItemName}</div>
            </div>
            <div className="flex-1 flex justify-end min-w-[8px] flex-col">
              <svg
                className="flex-grow self-end mr-1.5"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#212122"
                  strokeOpacity="0.62"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Link>
        ))}
      </div>
    </PageLayoutAndTitle>
  );
};

const PageLayoutAndTitle = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex-1">
      <h2 className="text-lg-heavy">Your equipment</h2>
      {children}
    </div>
  );
};
