import { twJoin } from 'tailwind-merge';
import Pill from '../../Labels/Pill/Pill';

interface Props {
  value?: string;
  setValue: (id: string) => void;
  className?: string;
  options: {
    label: string;
    value: string;
    counter?: number;
    disabled?: boolean;
  }[];
  isLoading?: boolean;
}

const PillSelect = ({
  value = '',
  setValue,
  className,
  options,
  isLoading = false,
}: Props) => {
  return (
    <div className={twJoin('flex flex-row gap-1 flex-wrap', className)}>
      {options.map((option) => (
        <Pill
          key={option.value}
          onClick={() => setValue(option.value)}
          selected={option.value === value}
          isLoading={isLoading}
          disabled={option.disabled}
          counter={option.counter}
        >
          {option.label}
        </Pill>
      ))}
    </div>
  );
};

export default PillSelect;
