import { Tooltip } from '@/martsUi/Components/Tooltip/Tooltip';
import { ComponentProps, ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

export type BadgeStatus = 'positive' | 'negative' | 'highlight' | 'neutral';

interface BadgeProps {
  emphasis?: boolean;
  status: BadgeStatus;
  className?: ComponentProps<'div'>['className'];
  tooltip?: string;
  children: ReactNode;
}

export const Badge = ({
  emphasis,
  status,
  className,
  tooltip,
  children,
}: BadgeProps) => {
  const bgColorEmphasis = {
    highlight: 'bg-highlight-strong',
    negative: 'bg-negative-strong',
    positive: 'bg-positive-strong',
    neutral: 'bg-text-light',
  } as const;
  const bgColorNonEmphasis = {
    highlight: 'bg-highlight-light',
    negative: 'bg-negative-light',
    positive: 'bg-positive-light',
    neutral: 'bg-bg-light',
  } as const;
  const textColorNonEmphasis = {
    highlight: 'text-highlight-dark',
    negative: 'text-negative-dark',
    positive: 'text-positive-dark',
    neutral: 'text-text-light',
  } as const;
  return (
    <Tooltip tooltip={tooltip}>
      <span
        className={twJoin(
          'inline-block py-1 px-2 rounded-lg whitespace-nowrap',
          emphasis
            ? `${bgColorEmphasis[status]} text-base-heavy text-white`
            : `${bgColorNonEmphasis[status]} ${textColorNonEmphasis[status]} text-sm-heavy`,
          className,
        )}
      >
        {children}
      </span>
    </Tooltip>
  );
};
