import React, { ComponentProps, ReactNode } from 'react';
import ProductThumbnail from '@/shared/ProductThumbnail/ProductThumbnail';

export const MartsGrid = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { className?: string }) => (
  <div
    className={`grid grid-cols-12 w-full gap-6 ${className ?? ''}`}
    {...props}
  >
    {children}
  </div>
);

const Info = ({
  children,
  className,
  thumbnailProductId,
  thumbnailNotAvailable,
}: {
  children: ReactNode;
  className?: ComponentProps<'div'>['className'];
  thumbnailProductId?: string;
  thumbnailNotAvailable?: boolean;
}) => (
  <div className={`col-span-8 pt-4 flex gap-6 ${className ?? ''}`}>
    {thumbnailProductId ? (
      <ProductThumbnail
        id={thumbnailProductId}
        width={80}
        height={80}
        notAvailable={thumbnailNotAvailable}
      />
    ) : (
      <div className={'w-[80px]'} />
    )}
    {children}
  </div>
);

Info.displayName = 'Info';
MartsGrid.Info = Info;

const TwoRight = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: ComponentProps<'div'>['className'];
}) => (
  <div className={`col-span-2 pt-4 text-right ${className ?? ''}`}>
    {children}
  </div>
);

TwoRight.displayName = 'TwoRight';
MartsGrid.TwoRight = TwoRight;
