import PillSelect from '@/martsUi/Buttons/PillSelect/PillSelect';

interface HomePageNewsCategoryProps {
  newsCategory: string;
  setNewsCategory: (value: string) => void;
}

export const HomePageNewsCategory = ({
  newsCategory,
  setNewsCategory,
}: HomePageNewsCategoryProps) => {
  const options = [
    { value: 'all', label: 'All' },
    { value: 'security', label: 'Security' },
    { value: 'hardware', label: 'Hardware' },
    { value: 'software', label: 'Software' },
    { value: 'business', label: 'Business' },
  ];

  return (
    <PillSelect
      value={newsCategory}
      setValue={setNewsCategory}
      options={options}
    />
  );
};
