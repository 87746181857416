import { memo, ReactNode } from 'react';
import classNames from 'classnames';
import { Tooltip } from '../../Components/Tooltip/Tooltip';
import { Counter } from '../Counter/Counter';

interface Props {
  isLoading?: boolean;
  className?: string;
  size?: 'sm' | 'md';
  children: ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit';
  form?: string;
  selected?: boolean;
  onClick?: () => void | Promise<void>;
  tooltip?: string;
  counter?: number;
}

const Pill = ({
  isLoading,
  className,
  size = 'md',
  children,
  disabled,
  type = 'button',
  form,
  selected = false,
  onClick,
  tooltip,
  counter,
}: Props) => {
  return (
    <button
      type={type}
      disabled={disabled}
      form={form}
      className={classNames({
        [className as string]: !!className,
        'transition-all rounded-l-full rounded-r-full relative whitespace-nowrap':
          true,
        'cursor-pointer': !disabled && !isLoading,
        'cursor-default': isLoading,
        'opacity-50 cursor-not-allowed': disabled,
        'px-4 py-2': size === 'md',
        'px-3.5 py-1.5': size === 'sm',
        'bg-white border-[1px] border-border-light': !selected,
        'hover:bg-bg': !selected && !isLoading,
        'bg-bg border-[1px] border-bg': selected,
      })}
      onClick={!isLoading ? onClick : undefined}
    >
      <Tooltip tooltip={tooltip}>
        <div className="inline-flex items-center">
          {children}
          {typeof counter === 'number' && (
            <Counter className={'ml-2.5'} count={counter} />
          )}
        </div>
      </Tooltip>
    </button>
  );
};

export default memo(Pill);
