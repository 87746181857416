import MartsPage from '@/shared/MartsPage/MartsPage';
import { HomePageYourEquipment } from './YourEquipment/HomePageYourEquipment';
import { HomePageRequests } from './Requests/HomePageRequests';
import { useValue } from 'use-change';
import { USERS } from '@/store/selectors';
import { HomePageNews } from './News/HomePageNews';

export default function HomePage() {
  const canApproveRequests = useValue(USERS, 'canApproveRequests');
  const isSystemAdmin = useValue(USERS, 'isSystemAdmin');
  return (
    <MartsPage title="For you">
      <div className={`${canApproveRequests ? 'grid grid-cols-2 gap-6' : ''}`}>
        <HomePageYourEquipment />
        {canApproveRequests && <HomePageRequests />}
      </div>
      {isSystemAdmin && (
        <div className={'mt-10'}>
          <HomePageNews />
        </div>
      )}
    </MartsPage>
  );
}
