import { useSilent, useValue } from 'use-change';
import { EQUIPMENT_ITEMS, PRODUCT_TYPES, PRODUCTS } from '@/store/selectors';
import { EquipmentItem, Product } from '@/types/index';
import useNullishValue from '@/lib/useNullishValue';
import { Image } from '@/martsUi/Components/Image/Image';
import useMap from '@/lib/useMap';

interface Props {
  id: Product['id'] | EquipmentItem['id'] | null;
  width: number;
  height: number;
  className?: string;
  notAvailable?: boolean;
}

/**
 * Supports both product ID and equipment item ID
 * @returns
 */
const ProductThumbnail = ({
  id,
  width,
  height,
  className,
  notAvailable,
}: Props) => {
  const product = useNullishValue(PRODUCTS.DATA, id);
  const equipmentItem = useNullishValue(EQUIPMENT_ITEMS.DATA, id);
  const getProductThumbnail = useSilent(PRODUCTS, 'getProductThumbnail');
  const productTypes = useMap(
    PRODUCT_TYPES.DATA,
    useValue(PRODUCT_TYPES, 'ids'),
  );

  const productType = productTypes.find(
    (productType) => productType.id === product?.type_id,
  );
  const type = productType?.value || equipmentItem?.type || '';
  let placeholder = '/images/assets/Placeholder.png';
  switch (type) {
    case 'Audio & Video':
    case 'Desktops':
    case 'Headphones & Headsets':
    case 'Keyboards & Mice':
    case 'Laptops':
    case 'Monitors':
    case 'Network':
    case 'Power & Cables':
    case 'Smartphones':
    case 'Software':
    case 'Tablets':
      placeholder = `/images/assets/${type}.png`;
      break;
  }

  let alt = 'No product image';
  let src = placeholder;
  if (equipmentItem) {
    alt = equipmentItem.equipmentItemName ?? 'Product Image';
    src = equipmentItem.imageUrl ?? placeholder;
  } else if (product) {
    alt = product.title ?? 'Product Image';
    src = getProductThumbnail(product.id) ?? placeholder;
  }

  return (
    <Image
      src={src}
      width={width}
      height={height}
      className={className ?? ''}
      imageClassName={`object-contain aspect-square`}
      notAvailable={notAvailable}
      alt={alt}
    />
  );
};

export default ProductThumbnail;
