import { useSilent, useValue } from 'use-change';
import { COMPANIES, NEWS, USERS } from '@/store/selectors';
import { useEffect, useState } from 'react';
import { LoadingPlaceholder } from '@/martsUi/Loading/LoadingPlaceholder/LoadingPlaceholder';
import { HomePageNewsCategory } from './Category/HomePageNewsCategory';
import { HomePageNewsArticle } from './Article/HomePageNewsArticle';

export const HomePageNews = () => {
  const me = useValue(USERS, 'me');
  const isServiceAgent = useValue(USERS, 'isServiceAgent');
  const myCompany = useValue(COMPANIES, 'myCompany');

  const loadNews = useSilent(NEWS, 'loadNews');
  const initialized = useValue(NEWS, 'initialized');
  const newsIds = useValue(NEWS, 'newsIds');

  const [newsCategory, setNewsCategory] = useState('all');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialized || !myCompany) return;

    setLoading(true);
    loadNews(newsCategory).then(() => {
      setLoading(false);
    });
  }, [loadNews, myCompany]);

  useEffect(() => {
    setLoading(true);
    loadNews(newsCategory).then(() => {
      setLoading(false);
    });
  }, [newsCategory]);

  if (me && !myCompany) return null;

  return (
    <div className="flex flex-col gap-6">
      <div className={'flex flex-col gap-4'}>
        <h2 className="text-lg-heavy">News</h2>

        <p className={'text-text-light'}>
          Personalised news based on the assets in your organisation
          {isServiceAgent && 's'}.
        </p>
      </div>

      <HomePageNewsCategory
        newsCategory={newsCategory}
        setNewsCategory={setNewsCategory}
      />

      {loading ? (
        <LoadingPlaceholder height={64} count={3} />
      ) : (
        <div>
          {newsIds.length === 0 && (
            <p className={'text-text-light'}>No news found for you.</p>
          )}
          {newsIds.slice(0, 3).map((id) => (
            <HomePageNewsArticle key={id} newsId={id} />
          ))}
        </div>
      )}
    </div>
  );
};
