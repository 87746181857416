import { useValue } from 'use-change';
import { NEWS } from '@/store/selectors';
import { MartsGrid } from '@/martsUi/Layout/MartsGrid/MartsGrid';
import Icon from '@/martsUi/Theme/Icon/Icon';
import { Badge } from '@/martsUi/Labels/Badge/Badge';
import { Image } from '@/martsUi/Components/Image/Image';

interface HomePageArticleProps {
  newsId: string;
}

export const HomePageNewsArticle = ({ newsId }: HomePageArticleProps) => {
  const article = useValue(NEWS.DATA, newsId);
  if (!article) return null;
  return (
    <MartsGrid
      className={'py-2 border-b border-border items-center cursor-pointer'}
      onClick={() => window.open(article.url, '_blank')}
    >
      <div className={'col-span-7 flex gap-4'}>
        {article.imageUrl ? (
          <Image
            src={article.imageUrl}
            width={64}
            height={64}
            alt={article.title}
            imageClassName={'rounded'}
          />
        ) : (
          <div className={'w-16 h-16 bg-gray-200 rounded-full flex-shrink-0'} />
        )}
        <div className={'flex flex-col'}>
          <div className={'text-base-heavy line-clamp-1'}>{article.title}</div>
          <div className={'line-clamp-2 text-text-light'}>
            {article.description}
          </div>
        </div>
      </div>
      <div />
      <div className={'col-span-3 flex flex-col gap-2'}>
        <div className={'text-text-light'}>{article.brands.join(', ')}</div>
        <div>
          <Badge
            status={article.category === 'security' ? 'negative' : 'neutral'}
            className={'capitalize'}
          >
            {article.category}
          </Badge>
        </div>
      </div>
      <div className={'flex justify-end'}>
        <Icon name={'linkExternal'} />
      </div>
    </MartsGrid>
  );
};
