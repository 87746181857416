import { useSilent, useValue } from 'use-change';
import { COMPANIES, REQUESTS, USERS } from '@/store/selectors';
import { ReactNode, useEffect, useState } from 'react';
import useMap from '@/lib/useMap';
import useData from '@/lib/useData';
import Link from 'next/link';
import Moment from 'react-moment';
import { LoadingPlaceholder } from '@/martsUi/Loading/LoadingPlaceholder/LoadingPlaceholder';
import { Image } from '@/martsUi/Components/Image/Image';
import { Counter } from '@/martsUi/Labels/Counter/Counter';

export const HomePageRequests = () => {
  const me = useValue(USERS, 'me');
  const canApproveRequests = useValue(USERS, 'canApproveRequests');

  const myCompany = useValue(COMPANIES, 'myCompany');

  const loadMyApproverRequests = useSilent(REQUESTS, 'loadMyApproverRequests');
  const [loadingApproverRequests, setLoadingApproverRequests] = useState(true);
  const myApproverRequestIds = useValue(REQUESTS, 'myApproverRequestIds');
  const activeRequests = useMap(REQUESTS.DATA, myApproverRequestIds);

  const userData = useData(USERS);

  useEffect(() => {
    if (!me || !myCompany) return;
    void loadMyApproverRequests().then(() => setLoadingApproverRequests(false));
  }, [loadMyApproverRequests, me, myCompany]);

  if (!canApproveRequests) return null;

  if (loadingApproverRequests) {
    return (
      <PageLayoutAndTitle>
        <div className={'flex flex-col mt-6 gap-1'}>
          <LoadingPlaceholder height={80} count={3} />
        </div>
      </PageLayoutAndTitle>
    );
  }

  if (activeRequests.length === 0) {
    return (
      <PageLayoutAndTitle requestCount={activeRequests.length}>
        <div className="text-center">
          <Image
            imageClassName="inline-block"
            src="/images/no-requests-illustration.png"
            width={95}
            height={100}
            alt="No requests"
          />
          <h3 className="mt-2 font-medium">Everybody’s fully equipped</h3>
          <p className="mt-2 text-text-light">
            When new needs arise order requests will appear here.
          </p>
        </div>
      </PageLayoutAndTitle>
    );
  }

  return (
    <PageLayoutAndTitle requestCount={activeRequests.length}>
      <div className="mt-6">
        {activeRequests.map((request) => {
          if (!request) return null;
          const { id, buyerUserId, createdAt, totalQuantity } = request;
          const buyer = userData[buyerUserId];
          return (
            <Link
              href={`/requests/${id}`}
              key={id}
              className="p-4 border-b border-border flex gap-6"
            >
              <div>
                <div>
                  {buyer
                    ? `${buyer.firstName} ${buyer.lastName}`
                    : 'Unknown user'}{' '}
                  has requested to order{' '}
                  <span className="font-medium">
                    {totalQuantity} item{totalQuantity > 1 ? 's' : ''}
                  </span>
                </div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Moment
                  className="text-text-light mt-2 block"
                  date={createdAt}
                  fromNow
                />
              </div>
              <div className="flex-1 flex flex-col justify-center items-end">
                <svg
                  className="opacity-60"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#212122"
                    strokeOpacity="0.62"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </Link>
          );
        })}
      </div>
    </PageLayoutAndTitle>
  );
};

const PageLayoutAndTitle = ({
  children,
  requestCount,
}: {
  children: ReactNode;
  requestCount?: number;
}) => {
  return (
    <div className="flex-1">
      <h2 className="text-lg-heavy">
        Requests
        {requestCount !== undefined && requestCount > 0 && (
          <Counter
            count={requestCount}
            className={'inline-block leading-[18px] align-middle ml-2'}
          />
        )}
        {requestCount === undefined && (
          <LoadingPlaceholder type={'circle'} className={'ml-2'} />
        )}
      </h2>
      {children}
    </div>
  );
};
